@tailwind base;
@tailwind components;
@tailwind utilities;

.theme-light .inverted {
  @apply bg-themes-light-invertedBackground text-themes-light-invertedText;
}

.theme-dark .inverted {
  @apply bg-themes-dark-invertedBackground text-themes-dark-invertedText;
}

:root {
  --background-color: #FAFAFA;
  --inverted-background-color: #2D2D2D;
  --text-color: #2D2D2D;
  --inverted-text-color: #FAFAFA;
  --primary-color: #FE3831;
  --secondary-color: #8B5CF6;
  --success-color: #4CAF50;
  --information-color: #2196F3;
  --error-color: #F44336;
  --warning-color: #FFC107;
}

.theme-dark {
  --background-color: #1E1E1E;
  --inverted-background-color: #FDC801;
  --text-color: #E4E4E4;
  --inverted-text-color: #1E1E1E;
  --primary-color: #FE3831;
  --secondary-color: #9333EA;
  --success-color: #4CAF50;
  --information-color: #2196F3;
  --error-color: #F44336;
  --warning-color: #FFC107;
}

.text-body {
  color: var(--text-color);
}

.text-inverted {
  color: var(--inverted-text-color);
}

.text-primary {
  color: var(--primary-color);
}

.text-success {
  color: var(--success-color);
}

.text-information {
  color: var(--information-color);
}

.text-error {
  color: var(--error-color);
}

.text-warning {
  color: var(--warning-color);
}

.bg-body {
  background-color: var(--background-color);
}

.bg-primary {
  background-color: var(--primary-color);
}

.bg-secondary {
  background-color: var(--secondary-color);
}

.bg-success {
  background-color: var(--success-color);
}

.bg-information {
  background-color: var(--information-color);
}

.bg-error {
  background-color: var(--error-color);
}

.bg-warning {
  background-color: var(--warning-color);
}

.text-black {
  color: #121212;
}

.text-poetsen {
  font-family: "Poetsen One", cursive;
  font-weight: 400;
  font-style: normal;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  font-family: 'Tilt Neon','Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom scrollbar for Webkit browsers */
.slim-scrollbar::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
}

.slim-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track background */
}

.slim-scrollbar::-webkit-scrollbar-thumb {
  background: var(--inverted-text-color); /* Thumb color */
}

.slim-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

/* Custom scrollbar for Firefox */
.slim-scrollbar {
  scrollbar-width: thin; /* 'thin' or can use 'auto' or a specific width like '8px' */
  scrollbar-color: var(--inverted-text-color) #f1f1f1; /* Thumb and track color */
}

.markdown-content a {
  text-decoration: underline;
}

.markdown-content hr {
  margin: 1.2em 0;
}

.bg-black-main {
  background-color: #121212;
}

.border-primary {
  border-color: var(--primary-color);
};

@keyframes talk {
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
}

.talking {
  animation: talk 0.4s ease-in-out infinite;
}

.merch-display {
  transition: opacity 300ms ease-in-out;
}

.merch-display.hidden {
  opacity: 0;
}

.merch-display.visible {
  opacity: 1;
}

.spinner {
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  0%, 25%, 100% {
      transform: translateX(0);
  }
  10%, 20% {
      transform: translateX(-10px);
  }
  15% {
      transform: translateX(10px);
  }
}

.shake {
  animation: shake 5s ease-in-out; /* Shake duration */
  animation-iteration-count: infinite;
  animation-delay: 5s; /* Time before the animation starts */
}

@keyframes pulse {
  0% {
      transform: scale(1);
      opacity: 0.9;
  }
  50% {
      transform: scale(1.1);
      opacity: 1;
  }
  100% {
      transform: scale(1);
      opacity: 0.9;
  }
}

.pulse {
  animation: pulse 2s infinite;
}